<template>
  <main>
    <div class="row mt-2">
      <div class="col-4 form-group" v-for="item in situaciones" :key="item.id">
        <label class="small mb-1">{{ item.situacion }}</label>
        <textarea rows="2" v-model="item.respuesta" class="form-control"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 d-flex justify-content-end">
        <button @click="guardar()" class="btn btn-success shadow-sm">Guardar</button>
      </div>
    </div>
  </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import situacionesPersonalesService from "../../../../services/situacionesPersonalesService";
import historiaSituacionesPersonalesService from "../../../../services/historiaSituacionesPersonalesService";
import { isEmpty } from "lodash";

export default {
  props: ["idHistoria", "finalizada", "idUsuario", "usuario"],
  data() {
    return {
      respuesta: {},
      situaciones: {},
      tieneRespuestas: false
    };
  },
  methods: {
    async cargarSituaciones() {

      const response = await situacionesPersonalesService.index({
        fecha_nacimiento: this.usuario.fecha_nacimiento
      });

      let respuestas =[];
      respuestas= await historiaSituacionesPersonalesService.showByHc(this.idHistoria);

      if (!isEmpty(respuestas.data)){
        this.tieneRespuestas = true;
      }else{
        respuestas = await historiaSituacionesPersonalesService.showByIdUsuario(this.usuario.id);
      }

      this.situaciones = response.data.map((situacion) => {

        let respuesta = 'NO REFIERE';

        if (!isEmpty(respuestas.data)) {

          const tieneRespuesta = respuestas.data.find(x => parseInt(x.id_situacion) === parseInt(situacion.id));

          if (tieneRespuesta !== undefined) {
            respuesta = tieneRespuesta.respuesta;
          }

        }

        return {
          ...situacion,
          respuesta: respuesta,
        };
      });
    },
    async guardar() {
      try {
        let historia_situaciones = {
          id_historia: this.idHistoria,
          respuestas: this.situaciones,
        };

        this.LoaderSpinnerShow();

        if (this.tieneRespuestas) {
          await historiaSituacionesPersonalesService.update(historia_situaciones);
        } else {
          await historiaSituacionesPersonalesService.store(historia_situaciones);
          this.tieneRespuestas = true;
        }

        this.LoaderSpinnerHide();

        Toast.fire({
          icon: 'success',
          title: 'Datos guardados con exito'
        });
      } catch (e) {
        console.error(e);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: 'error',
          title: 'Ocurrio un error al procesar la solicitud'
        });
      }
    },
  },
  created() {
    this.cargarSituaciones();
  },
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>